.owl-theme .owl-nav .disabled {
  opacity: 1 !important;
  background-color: #e2e0e0 !important;
}

.disabled.owl-next {
  background-image: url("../../assets/images/icon/general/icn_freccia_dx.svg") !important;
}

.disabled.owl-prev {
  background-image: url("../../assets/images/icon/general/icn_freccia_sn.svg") !important;
}

.owl-next,
.owl-prev {
  width: 40px;
  height: 40px;
  border-radius: 30px !important;

  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 12px !important;
  position: absolute;
  top: 50%;
  background-color: white !important;
  box-shadow: #bab5b54a 0 0 20px 3px;
  opacity: 1;

  span {
    display: none;
  }

  @media only screen and (min-width: 200px) and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }

}

.owl-next {
  right: 0;
  background-image: url("../../assets/images/icon/general/icn_freccia_dx.svg") !important;
}

.owl-prev {
  left: 0;
  background-image: url("../../assets/images/icon/general/icn_freccia_sn.svg") !important;
}
